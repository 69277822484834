import "./review.scss";
import classNames from "classnames";
import { useResponsive } from "../../../hook/useResponsive";
import { useMemo } from "react";
import { REVEIW_LIST } from "./ReviewList.const";
import { SoThumbsUp } from "../../../resources/icon/solidIcon";
import { Content } from "../sectionLayout/SectionLayout";

const GRADIENT_BLOCK_POPSITION = {
  LEFT: "left",
  RIGHT: "right"
};

const CLASSNAME = "review"

export const ReviewContent = () => {
  const { isMob } = useResponsive();
  const reviewList = useMemo(() => {
    return REVEIW_LIST.sort(() => Math.random() - 0.5);
  }, []);

  return (
    <Content className={CLASSNAME} >
      {!isMob && <ReviewListGradient position={GRADIENT_BLOCK_POPSITION.LEFT} />}
      <div className="reveiw-list-w">
        <ReviewList reviewList={reviewList.slice(0, 7)} />
        <ReviewList reviewList={reviewList.slice(7)} />
      </div>
      {!isMob && <ReviewListGradient position={GRADIENT_BLOCK_POPSITION.RIGHT} />}
    </Content>
  )
};

const ReviewList = ({reviewList}) => {


  return (
    <div className="review-list-viewport" >
      <div className="review-list" >
        {reviewList && reviewList.length > 0 && reviewList.map((review, index) => {
          return <ReviewItem review={review} key={`review-list-${index}`} />
        })}
      </div>
    </div>
  );
};

const ReviewListGradient = ({position}) => {
  return (
    <div className={classNames("review-list-gradient", position)} >
    </div>
  );
};


const ReviewItem = ({review}) => {
  return (
    <span className="review-item-w" >
      <div className="r-reviewer-info">
        <span className="r-profile-img-w" >
          <img className="profile-img" src={`${process.env.REACT_APP_IMAGE_URL}/resource/gpt/profile/${review.profile}`} alt="PROFILE_IMG" />
        </span>
        <span className="r-name">
          {review.id}
        </span>
        <SoThumbsUp className="r-icon" />
      </div>
      <div className="r-content" >
        {review.testimonial}
      </div>
    </span>
  );
};