import "./sectionFooter.scss";
import { FooterImage } from "../../resources/image";
import { Content, ContentContainer, ContentDescription, ContentTitle, ImageContainer } from "../component/sectionLayout/SectionLayout";
import { AppStoreLink, PlayStoreLink, ProductHuntBadge, ROUND_BUTTON, RoundButton, RoundButtonWithDesc } from "../../component/button/Button";
import { TextHighlight } from "../../component/text/Text";
import { useResponsive } from "../../hook/useResponsive";
import { useCallback } from "react";
import classNames from "classnames";

const CLASSNAME = "section-footer"

export const SectionFooter = () => {
  const { isMob } = useResponsive();

  return (
    <>
      {!isMob &&
        <Content className={CLASSNAME} >
          <ContentContainer className={CLASSNAME} >
            <ContentTitle className={CLASSNAME} >
              Anytime. Anywhere.
            </ContentTitle>
            <ContentDescription className={CLASSNAME} >
              Stay in control wherever you are with Minimap.
            </ContentDescription>
            <AppLinkContainer />
          </ContentContainer >
          <ImageContainer className={CLASSNAME} >
            <FooterImage className={CLASSNAME} />
          </ImageContainer>
        </Content>
      }
      <FooterDescription />
      {isMob && 
        <div className="mobile-badge-w" >
          <ProductHuntBadge className={CLASSNAME} />
        </div>
      }
    </>
  );
};

export const FooterDescription = () => {
  const onClick = useCallback(() => {
    window.open("https://minimap.net/popup/register?utm_source=minimap&utm_medium=referral&utm_campaign=minimap_landing_page");
  }, []);

  return (
    <footer className="footer-desc section-footer" >
      <Description />
      <RoundButtonWithDesc className={CLASSNAME} onClick={onClick} />
    </footer>
  );
};

const Description = () => {
  return (
    <div className="description section-footer" >
      <TextHighlight >Join Minimap today</TextHighlight>{` and\nstart your next gaming adventure!`}
    </div>
  );
};

const AppLinkContainer = () => {
  return (
    <div className="app-link-container" >
      <WebLink />
      <AppStoreLink className={CLASSNAME} />
      <PlayStoreLink className={CLASSNAME} />
    </div>
  );
};

const WebLink = () => {
  return (
    <RoundButton 
      className={classNames("web-link", CLASSNAME, ROUND_BUTTON.COLOR.TRANSPARENT, ROUND_BUTTON.SIZE.LARGE)}
    >
      <a href="https://minimap.net/popup/register?utm_source=minimap&utm_medium=referral&utm_campaign=minimap_landing_page" target="_blank" rel="noreferrer" >
        Website
      </a>
    </RoundButton>
  );
};