import "./index.scss";
import classNames from "classnames";
import Emoji_finger1 from "./finger1.png";
import Emoji_finger2 from "./finger2.png";
import Emoji_finger3 from "./finger3.png";

export const OneFinger = ({className, ...props}) => {
  return (
    <img className={classNames("emoji-img", className)} src={Emoji_finger1} alt="" {...props} />
  );
};

export const TwoFingers = ({className, ...props}) => {
  return (
    <img className={classNames("emoji-img", className)} src={Emoji_finger2} alt="" {...props} />
  );
};

export const ThreeFingers = ({className, ...props}) => {
  return (
    <img className={classNames("emoji-img", className)} src={Emoji_finger3} alt="" {...props} />
  );
};