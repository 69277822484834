import MainIntro_Web from "./main_intro_web.mp4";
import MainIntro_Mobile from "./main_intro_mobile.mp4";


export const MainIntroWeb = ({className}) => {
  return (
    <video 
      className={className}
      src={MainIntro_Web}
      autoPlay={true}
      controls={false}
      loop={true}
      muted={true}
      playsInline={true}
    />
  );
};

export const MainIntroMobile = ({className}) => {
  return (
    <video 
      className={className}
      src={MainIntro_Mobile}
      autoPlay={true}
      controls={false}
      loop={true}
      muted={true}
      playsInline={true}
    />
  );
};